import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

import { useNotify, useUser } from '@moved/services';
import { ProxyModeBadge, RouteTree } from '@moved/product';
import { LoaderOverlay } from '@moved/ui';

import { getVendor } from '../../features/common/actions';
import { useVendor, useVendorPending } from '../../features/common/actions/selectors';
import { routeTree } from '../routes';

import CSS from '../styles/App.module.scss';

export const App = () => {
  const { user } = useUser();
  const notify = useNotify();
  const dispatch = useDispatch();
  // redux
  const vendor = useVendor();
  const pending = useVendorPending();

  // Fetch vendor data on load
  useEffect(() => {
    if(get(user,'vendor_id')) dispatch(getVendor(get(user,'vendor_id'))).catch((error) => notify.error());
  },[user, notify, dispatch]);

  if(pending) return <LoaderOverlay/>;
  if(get(user,'vendor_id') && !vendor) return null;
  return (
    <div className={CSS.vendor_app}>
      <RouteTree routeTree={routeTree} defaultDomains={['vendor']} />
      <ProxyModeBadge/>
    </div>
  );
};
