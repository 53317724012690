import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { get } from 'lodash';
import moment from 'moment';
import Moment from 'react-moment';

import { useNotify, useUser, format } from '@moved/services';
import { LoaderOverlay, Collapsible, Icon } from '@moved/ui';

import { LayoutLanding } from '../../common';
import { getJobOffer, useJobOffer, useJobOfferPending } from '../actions/getJobOffer';
import { JobDetailsList } from './JobDetailsList';
import { FieldList } from './FieldList';
import CSS from '../styles/ConfirmJob.module.scss';

export const ConfirmJob = ({ children, match }) => {
  const { token } = useParams();
  const { hasDomain } = useUser();
  const dispatch = useDispatch();
  const notify = useNotify();

  // redux
  const pending = useJobOfferPending();
  const jobOffer = useJobOffer(token);

  // state
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    dispatch(getJobOffer(token)).catch(error => notify.error(format.error(error)));
  },[token, dispatch, notify]);

  if(pending) return <LoaderOverlay/>;
  else if(!jobOffer) return <Error/>;

  const { job } = jobOffer;
  const hasExpired = moment.utc(jobOffer.expires_at).isBefore();
  const displayRelative = moment.utc(jobOffer.expires_at).isBefore(moment().add(2,'h'));
  const status = hasExpired ? 'expired' : jobOffer.status;

  // DISPLAY FIELDS
  const fields = [
    {
      label: 'Customer name',
      value: `${get(job, 'customer.firstname')} ${get(job, 'customer.lastname')}`,
    },
    {
      label: 'Job Number',
      value: job.job_number,
    },
    {
      label: 'Pick up Date',
      value: job.service_date && moment(job.service_date).format('MMMM D, YYYY'),
    },
    {
      label: 'Arrival Window',
      value:  ['pending','accepted'].includes(status) && // hide unless pending or accepted
        get(job,'earliest_arrival_time') && get(job,'latest_arrival_time') && // hide if arrival data is empty
        `${get(job,'earliest_arrival_time')} - ${get(job,'latest_arrival_time')}`, // render the display value
    },
    {
      label: 'Customer Time Preference',
      value:  ['pending','accepted'].includes(status) && // hide unless pending or accepted
        get(job, 'mover_booking.time_preference'),
    },
    {
      label: 'Crew Size',
      value:  job.crew_size && `${job.crew_size} movers`,
    },
    {
      label: 'Offer Expiration',
      value:  ['pending','expired'].includes(status) &&
        <Moment utc local fromNowDuring={displayRelative} format='MMMM D, YYYY h:mma'>{jobOffer.expires_at}</Moment>,
    },
    job.is_hourly && {
      label: 'Customer Rate',
      value: job.rate && `${format.currency(job.rate)}/hour`,
    },
    job.is_hourly && {
      label: 'Customer Minimum',
      value: job.minimum && format.currency(job.minimum),
    },
    job.is_hourly && {
      label: 'Your Rate',
      value: jobOffer.vendor_rate && format.currency(jobOffer.vendor_rate),
    },
    !job.is_hourly && {
      label: 'Customer Price',
      value: job.estimated_price && format.currency(job.estimated_price),
    },
    !job.is_hourly && {
      label: 'Your Rate',
      value: jobOffer.vendor_estimated_revenue && format.currency(jobOffer.vendor_estimated_revenue),
    },
    job.coupon_total && {
      label: 'Partner Coupon',
      value: job.coupon_total && format.currency(job.coupon_total),
    },
  ].filter(f => f); // filter conditionally hidden fields


  // DISPLAY COPY
  const display = getStatusDisplay(status);
  if(!display) return (<Error/>)

  return (
    <LayoutLanding>
      <div className={CSS.container}>

        <div className='txt-center'>
          { display.icon }
          <h1 className='txt-center mb-20'>{ display.title }</h1>
        </div>

        <p>{ display.body }</p>

        <h3 className={CSS.list_title}>Move details</h3>
        <div>
          <FieldList fields={fields}/>
        </div>

        { display.showDetails && (
          <>
          <div className={'mt-20'}>
            <span className={'faux-link '+CSS.toggle_link} onClick={() => setExpanded(!expanded)}>
              { expanded ? 'Hide details' : 'View more details' }
            </span>
          </div>
          <Collapsible open={expanded}>
            <div className='mt-20'>
              <JobDetailsList job={job}/>
            </div>
          </Collapsible>
          </>
        )}

        { display.showActions && match.isExact && (
          <div className='mt-30 flex justify-center'>
            <Link to={`${match.url}/accept`} className={'btn-primary btn--full'}>
              Accept Job
            </Link>
            <Link to={`${match.url}/decline`} className={'faux-link mt-30'}>
              Decline Job
            </Link>
          </div>
        )}

        { display.showDashboardLink && !hasDomain('guest') && (
          <div className='mt-30 flex justify-center'>
            <Link to={'/jobs'} className={'btn-primary btn--full'}>
              Go to Dashboard
            </Link>
          </div>
        )}

        { children }

      </div>
    </LayoutLanding>
  );

};

const getStatusDisplay = (status) => {
  switch(status) {
    case 'pending':
      return {
        title: 'Accept Job?',
        body: (<>
          Right after you accept this job, the customer will be introduced to you by email.
          This email will include the arrival window you provide below.
        </>),
        showDetails: true,
        showActions: true,
      };
    case 'accepted':
      return {
        icon: (<Icon size='44px' symbol='Done' color='green' className={CSS.icon}/>),
        title: 'Job Confirmed!',
        body: (<>
          Great! You will be introduced to the customer via email shortly.
          This email will reference the arrival window you submitted below.
        </>),
        showDetails: true,
        showDashboardLink: true,
      };
    case 'declined':
      return {
        icon: (<Icon size='44px' symbol='Error' color='red' className={CSS.icon}/>),
        title: 'Job Declined!',
        body: (<>
          You've declined this job and it will be assigned to another moving company with open availability.
        </>),
      };
    case 'expired':
      return {
        icon: (<Icon size='44px' symbol='Warning' color='red' className={CSS.icon}/>),
        title: 'Job offer has expired',
        body: (<>
          This job is no longer available. In order to give customers the best
          experience we attempt to confirm availability as quickly as possible.
        </>),
      };
    default:
      return false;
  }
};

const Error = () => (
  <LayoutLanding>
    <div className={CSS.container}>
      <div className='txt-center'>
        <Icon size='44px' symbol='Warning' color='red' className={CSS.icon}/>
        <h1 className='txt-center mb-20'>Link is invalid or expired</h1>
      </div>
      <p>
        Please double check the url, and reach out to us if this issue persists.
      </p>
    </div>
  </LayoutLanding>
);
