import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { merge } from 'lodash';

import { useScroller, useMobile, format } from '@moved/services';
import { SlideToggle, LoaderOverlay, SortTable, Pagination, CardList, EmptyContent } from '@moved/ui';

import { LayoutStandard, HeaderSelect } from '../../common';
import { getJobs, useJobs, useJobsPending } from '../actions/getJobs';

import bookedJobsCSS from '../styles/BookedJobs.module.scss';

const defaultView = {
  status: 'active',
  sort_by: 'move_date',
  order: 'desc',
};
const previousView = JSON.parse(localStorage.getItem('vendorJobsView') || '{}');
const initialView = merge(defaultView,previousView);

export const BookedJobs = (props) => {
  // HOOKS
  const scroller = useScroller();

  const isMobile = useMobile();
  const dispatch = useDispatch();
  const history = useHistory();

  // GENERAL STATE
  const RESULTS_PER_PAGE = 25;

  // FILTER STATE
  const filters = [
    {label: "Active", value: 'active'},
    {label: "Complete", value: 'completed'},
    {label: "All", value: 'all'},
  ];
  const [activeFilterName, setActiveFilterName] = useState(initialView.status);
  const activeFilter = filters.find(filter => filter.value === activeFilterName);

  // SORT STATE
  const [activeSort, setActiveSort] = useState(initialView.sort_by);
  const [activeSortDirection, setActiveSortDirection] = useState(initialView.order === 'asc');

  // PAGINATION STATE
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // JOBS STATE
  const jobs = useJobs();
  const pending = useJobsPending();
  const loadJobs = () => {
    const params = {
      status: activeFilterName,
      page: activePage,
      sort_by: activeSort,
      order: activeSortDirection === true ? 'asc' : 'desc',
      limit: RESULTS_PER_PAGE,
    }
    localStorage.setItem('vendorJobsView', JSON.stringify(params));
    dispatch(getJobs(params));

    scroller.ref.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    loadJobs();
  // eslint-disable-next-line
  },[activeFilterName,activeSort,activeSortDirection,activePage]);

  useEffect(() => {
    const newPageCount = Math.ceil(jobs.totalResults / RESULTS_PER_PAGE);
    if(newPageCount !== totalPages) setTotalPages(newPageCount);
  // eslint-disable-next-line
  },[jobs]); // run whenever param data changes

  // CONVENIENCE FUNCTIONS
  const showSummary = (job) => history.push(`/jobs/${job.id}`);
  const markDelivered = (job, e) => {
    if(e) e.stopPropagation(); // keep the row click from being triggered
    // TODO: once we have a v0 get vendor endpoint we need to handle the stripeConnect modal (which also doesn't exist in react yet)
    // if(!vendor.has_stripe) return Payment.modals.stripeConnect(this.vendor, true);
    history.push(`/jobs/${job.id}/finalize`);
  };
  const markConfirmed = (job, e) => {
    if(e) e.stopPropagation(); // keep the row click from being triggered
    history.push(`/jobs/${job.id}/confirm`);
  };

  const updateSort = (column) => {
    setActivePage(1); // when sort changes, reset to first page
    if(activeSort === column.name) {
      setActiveSortDirection(!activeSortDirection);
    }
    else {
      setActiveSort(column.name);
      setActiveSortDirection(column.direction || false);
    }
  };

  const updateFilter = (selected) => {
    setActivePage(1); // when filter changes, reset to first page
    setActiveFilterName(selected);
  };

  // SORT TABLE LAYOUT (Desktop)
  const columns = [
    {
      name: 'customer_fullname',
      label: 'Customer',
      value: ({customer}) => `${customer.lastname}, ${customer.firstname}`,
      class: isMobile ? bookedJobsCSS.customer_field : bookedJobsCSS.customer_column,
      direction: true,
      sortable: true
    },{
      name: 'move_date',
      label: 'Move Date',
      value: job => job.service_date ? moment(job.service_date).format('MM/DD/YY') : (<>&mdash;</>),
      class: isMobile ? bookedJobsCSS.move_date_field : bookedJobsCSS.move_date_column,
      direction: false,
      sortable: true,
    },{
      name: 'rate',
      label: 'Rate',
      value: job => {
        if(job.status === 'Delivered') return (job.final_price || job.total) ? format.currency(job.final_price || job.total) : '&mdash;'
        if(job.is_hourly) return job.rate ? format.currency(job.rate) + ' / hour' : '&mdash;';
        else return (job.estimated_price || job.total) ? format.currency(job.estimated_price || job.total) : '&mdash;';
      },
      class: isMobile ? bookedJobsCSS.rate_field : bookedJobsCSS.rate_column,
    },{
      name: 'revenue',
      label: 'Revenue',
      value: job => {
        if(job.is_hourly && job.status !== 'Delivered') return 'TBD';
        else return format.currency(job.revenue);
      },
      class: isMobile ? bookedJobsCSS.revenue_field : bookedJobsCSS.revenue_column,
    },{
      name: 'status',
      label: 'Status',
      value: job => (
        <span className={bookedJobsCSS[`status_${format.safeClass(job.status)}`]}>{job.status}</span>
      ),
      class: isMobile ? bookedJobsCSS.status_field : bookedJobsCSS.status_column,
    },{
      name: 'action',
      label: '',
      value: job => (
        job.status === 'In Transit' ? (
          <div className={isMobile ? 'faux-link' : 'btn-primary btn--small'} onClick={event => markDelivered(job,event)}>Mark as Delivered</div>
        ) :
        job.status === 'Unconfirmed' ? (
          <div className={isMobile ? 'faux-link' : 'btn-primary btn--small'} onClick={event => markConfirmed(job,event)}>Confirm</div>
        ) : null
      ),
      class: isMobile ? bookedJobsCSS.action_field : bookedJobsCSS.action_column,
    }
  ];

  const findColumn = (name) => columns.find(col => col.name === name);
  // CARD LIST LAYOUT (Mobile)
  const cardLayout = [
    [
      findColumn('customer_fullname'),
      findColumn('status'),
    ],[
      findColumn('move_date'),
      findColumn('rate'),
      findColumn('revenue'),
    ],[
      {
        name: 'details',
        label: '',
        value: job => (
          <div className={'faux-link'} onClick={() => showSummary(job)}>View Details</div>
        ),
        class: bookedJobsCSS.action_field,
      }
    ],[
      findColumn('action'),
    ],
  ];

  // append refreshJobs prop to children
  const children = React.Children.toArray(props.children)
    .map(child => React.cloneElement(child, { refreshJobs: loadJobs }));

  return (
    <LayoutStandard
      leftHeaderContent={
        !isMobile && (<>Booked Jobs</>)
      }
      rightHeaderContent={
        isMobile ? (
          <HeaderSelect
            name='view'
            value={activeFilter}
            options={filters}
            onChange={(filter) => updateFilter(filter.value)}
          />
        ) : (
          <SlideToggle
            name='activeFilter'
            value={activeFilterName}
            options={filters}
            onChange={({activeFilter}) => updateFilter(activeFilter)}
            isControlled
            size='large'
          />
        )
      }>

      <Helmet>
        <title>Booked Jobs : Moved</title>
      </Helmet>

      { pending && (<LoaderOverlay />)}

      <div className={bookedJobsCSS.content}>
        { jobs.activeSet.length ? (
          isMobile ? (
            <CardList items={jobs.activeSet} layout={cardLayout} />
          ) : (
            <SortTable
              columns={columns}
              rows={jobs.activeSet}
              activeSort={{
                column:activeSort,
                direction: activeSortDirection
              }}
              rowClick={showSummary}
              updateSort={updateSort}
            />
          )
        ) : (
          <div className='width-full height-full stackVertical items-center justify-center'>
            <EmptyContent
              message={activeFilter.value === 'all' ? 'No moves yet!' : `No ${activeFilter.label.toLowerCase()} moves`}
              iconSize='50px'
              className='width-half'
            />
          </div>
        )}
        {/* Page nav */}
        { totalPages > 1 && (
          <Pagination
            page={activePage}
            pageCount={totalPages}
            onPageChange={setActivePage}
            className={bookedJobsCSS.pager}
          />
        )}
        { children }
      </div>
    </LayoutStandard>
  );
};
