import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { noop } from 'lodash';

import { useModal, useNotify } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';

import { FinalizeJobModal } from './FinalizeJobModal';
import { getJobDetails, useJobDetailsPending } from '../actions/getJobDetails';

export const FinalizeJob = ({parent, refreshJobs=noop}) => {
  const { active } = useParams();
  const history = useHistory();
  const modal = useModal();
  const notify = useNotify();
  const dispatch = useDispatch();

  // redux
  const pending = useJobDetailsPending();

  useEffect(() => {
    dispatch(getJobDetails(active))
      .then(job => {
        if(job.status !== 'In Transit') return history.replace(parent);
        modal.openLegacy(<FinalizeJobModal job={job} />, {
          onClose: (result) => {
            if(result) refreshJobs(); // passed down from parent BookedJobsList
            result ? history.replace(parent) : history.push(parent);
          },
        });
      })
      .catch(() => notify.error(`We're sorry, we were unable to fetch information about that job.`));
  // eslint-disable-next-line
  },[active]);

  return pending ? (<LoaderOverlay/>) : null;
};
